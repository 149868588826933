<i class="bi bi-person-plus-fill colorIconAction"></i><b class="modalTitleModify">{{'users.edit_user' | translate}}</b>
<hr />
<div mat-dialog-content>
  <form [formGroup]="editUserForm">
    <!-- Fila 1 -->
    <div class="row">
      <div class="col-6">
        <label for="name">{{ 'auth.register.name' | translate }}</label>
        <input class="inputSize customInput" type="text" name="name" id="name" formControlName="name"
          placeholder="{{ 'auth.register.name' | translate }}">
      </div>
      <div class="col-6">
        <label for="email">{{ 'auth.register.email' | translate }}</label>
        <input class="inputSize customInput" type="text" name="email" id="email" formControlName="email"
          placeholder="{{ 'auth.register.email' | translate }}">
      </div>
    </div>

    <!-- Fila 2 -->
    <div class="row">
      <div class="col-6">
        <label for="password">{{ 'auth.register.password' | translate }}</label>
        <input class="inputSize customInput" type="password" name="password" formControlName="password" id="password"
          placeholder="{{ 'auth.register.password' | translate }}">
      </div>
      <div class="col-6">
        <label for="countryCode">{{ 'create_product.country_crp' | translate }}</label>
        <select class="inputSize customInput" id="countryCode" formControlName="countryCode">
          <option *ngFor="let country of countries" [value]="country.countryCode">{{ getCountryNameByLanguage(country)
            }}
          </option>
        </select>
      </div>
    </div>

    <!-- Fila 3 -->
    <div class="row">
      <div class="col-6">
        <label for="defaultLanguageCode">{{ 'Questions.language' | translate }}</label>
        <select class="inputSize customInput" id="defaultLanguageCode" formControlName="defaultLanguageCode">
          <option *ngFor="let language of languages" [value]="language.languageCode">{{ language.name }}</option>
        </select>
      </div>
      <div class="col-6">
        <label for="roleSelect">{{ 'users.roles' | translate }}</label>
        <select class="inputSize customInput" id="roleSelect" formControlName="roles" (change)="onRoleChange($event)">
          <option [value]="2">{{'users.administrator' | translate}}</option>
          <option [value]="1">{{'users.user' | translate}}</option>
          <option [value]="4">{{'users.admin_country' | translate}}</option>
          <option value="" disabled selected>{{ 'users.add_role' | translate }}</option>
        </select>
      </div>
    </div>

    <!-- Selector adicional para Administrador -->
    <div class="row" *ngIf="isCountryAdmin">
      <div class="col-6">
      <label for="adminEditableCountries">{{ 'users.validCountriesByUser' | translate }}</label>
        <mat-form-field appearance="fill" class="fullWidth">
          <mat-label>{{ 'users.valid_countries' | translate }}</mat-label>
          <mat-select formControlName="adminEditableCountries" [(ngModel)]="selectedCountries" multiple>
            <mat-option *ngFor="let country of countries" [value]="country">
              {{ getCountryNameByLanguage(country) }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="loginButtons">
      <button class="sendButton" type="submit" (click)="editUserFromAdmin()">{{'create_product.save_crp' |
        translate}}</button>
      <button class="closeButton" type="button" [mat-dialog-close]="false">{{'users.cancel' | translate}}</button>
    </div>
  </form>
</div>