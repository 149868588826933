import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { EditorialsBooks } from 'src/app/users/models/create-editorialsBooks.model';
import { EditorialsBooksService } from '../../services/editorials-books.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'app-create-editorials-books',
  templateUrl: './create-editorials-books.component.html',
  styleUrls: ['./create-editorials-books.component.scss']
})
export class CreateEditorialsBooksComponent implements OnInit {

  createUserForm: FormGroup;


  constructor(
    // public dialogRef: MatDialogRef<CreateEditorialsBooksComponent>,
    private _dialogRef: MatDialogRef<CreateEditorialsBooksComponent>,
    private _EditorialsBooksService: EditorialsBooksService,
    private fb: FormBuilder,

  ) { 
    this.createUserForm = this.fb.group({
      name: ['', Validators.required],
      description: ['', Validators.required],
    });
  }

  ngOnInit(): void {
  }


  createNewEditorialBook(){
    //console.log('crear nueva editorial');

        const newUserRequest: EditorialsBooks = {
          name: this.createUserForm.get("name")?.value,
          description: this.createUserForm.get("description")?.value,
        };

        //console.log('datos a enviar:',newUserRequest);
        //logica para hacer la solicitud de creacion mediante el servicio
        this._EditorialsBooksService.createEditorialBook(newUserRequest)
        .subscribe({
          next: (data) => {
            //console.log('Datos recibidos:', data);
            this._dialogRef.close();

          },
          error: (err) => {
            console.error('Error al guardar la editorial:', err);
          },
          complete: () => {
            //console.log('Petición completada.');
          }
        });

  }

}
