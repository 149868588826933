<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="UTF-8">
  <meta http-equiv="X-UA-Compatible" content="IE=edge">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <link href='https://fonts.googleapis.com/css?family=Roboto' rel='stylesheet'>
  <title>Document</title>
</head>

<body>
  <div class="main">
    <div class="row">
      <!-- Header -->
      <div class="row headerListBook">
        <div class="col-12 p-2">
          <b class="titleListBook">{{'my_books.all_prodcts_mb' | translate}}</b>
        </div>
        <div class="col-7"></div>
        <br>
      </div>
      <div class="row">
        <div class="col">
          <div class="row">
            <div class="col">
              <p class="descriptionViewListBook">
                {{'my_books.message_my_books_mb' | translate}}
              </p>
            </div>
          </div>
          <br>
          <div class="row">
            <ng-container *ngIf="dataSource.data.length > 0 else elseTemplate">
              <div class="mat-elevation-z8">
                <table mat-table [dataSource]="dataSource">

                  <!-- Image Column -->
                  <ng-container matColumnDef="imgName">
                    <th mat-header-cell *matHeaderCellDef class="sizeThead text-center"><b>{{'my_books.image_mb' |
                        translate}}</b></th>
                    <td mat-cell *matCellDef="let element" class="imgColSize"><img class="imgBook"
                        [src]="api_path_img + element.productCoverImageName" alt="vacio"> </td>
                  </ng-container>

                  <!-- id Column -->
                  <ng-container matColumnDef="id">
                    <th mat-header-cell *matHeaderCellDef class="text-center"><b>{{'my_books.book_code_mb' |
                        translate}}</b></th>
                    <td mat-cell *matCellDef="let element" class="colSize"><b class="sizeMaxText">{{element.id}}</b>
                    </td>
                  </ng-container>

                  <!-- Code Column -->
                  <ng-container matColumnDef="productCode">
                    <th mat-header-cell *matHeaderCellDef class="text-center"><b>{{'my_books.list_product_code' |
                        translate}}</b></th>
                    <td mat-cell *matCellDef="let element" class="colSize"><b class="sizeMaxText">{{element.isbn ||
                        element.issn}}</b></td>
                  </ng-container>

                  <!-- Product code Column -->
                  <ng-container matColumnDef="code">
                    <th mat-header-cell *matHeaderCellDef class="text-center"><b>{{'my_books.book_code_mb' | translate}}
                      </b></th>
                    <td mat-cell *matCellDef="let element" class="colSize"><b
                        class="sizeMaxText">{{element.interactiveCode}}</b></td>
                  </ng-container>

                  <!-- Title Column -->
                  <ng-container matColumnDef="title">
                    <th mat-header-cell *matHeaderCellDef class="text-center"><b>{{'create_product.title_crp' |
                        translate}}</b></th>
                    <td mat-cell *matCellDef="let element" class="shortcolSize"><b
                        class="sizeMaxText">{{getBookTitleByLanguage(element.id)}}</b></td>
                  </ng-container>

                  <!-- Editorial Book Column -->
                  <ng-container matColumnDef="editoriaBook">
                    <th mat-header-cell *matHeaderCellDef class="text-center">
                      <button *ngIf="!isFilterVisible" (click)="showFilterInput()"
                        class="bi bi-funnel colorIconAction" [ngClass]="{'filter-active-icon': isFilterActive}"></button>
                      <b [ngClass]="{'filter-active-title': isFilterActive}"> {{'create_product.editorialBook_crp' | translate}} </b>

                      <!-- Popover para el filtro -->
                      <div *ngIf="isFilterVisible" class="filter-popover popover-container">
                        <input #filterInput matInput
                          placeholder="{{'create_product.filterEditorialBook_crp' | translate}}"
                          [(ngModel)]="filterValue" (keyup)="applyFilter($event)" (blur)="hideFilterInput()">
                      </div>
                    </th>
                    <td mat-cell *matCellDef="let element" class="shortcolSize"><b
                        class="sizeMaxText">{{getEditorialBook(element.id)}}</b></td>
                  </ng-container>

                  <!-- Abstract -->
                  <ng-container matColumnDef="resume">
                    <th mat-header-cell *matHeaderCellDef class="text-center"><b>{{'create_product.summary_crp' |
                        translate}}</b></th>
                    <td mat-cell *matCellDef="let element" class="resumecolSize">
                      <p class="textDecriptionSize">{{getBookResumeByLanguage(element.id)}}</p>
                    </td>
                  </ng-container>

                  <!-- Languages -->
                  <ng-container matColumnDef="multilanguageText">
                    <th mat-header-cell *matHeaderCellDef class="text-center">
                      <b>{{'create_product.multi_language_text_crp' | translate}}</b>
                    </th>
                    <td mat-cell *matCellDef="let element" class="colSize">
                      <p *ngFor="let langName of getMultiLanguageText(element.id)"><b
                          class="sizeMaxText">{{langName}}</b></p>
                    </td>
                  </ng-container>

                  <!-- documentation Link -->
                  <ng-container matColumnDef="docLink">
                    <th mat-header-cell *matHeaderCellDef class="text-center"><b>{{'my_books.docLinkTitle' |
                        translate}}</b></th>
                    <td mat-cell *matCellDef="let element" class="colSize"><a href="../../assets/userManual_es.pdf"
                        target="_blank" rel="noopener noreferrer">{{'my_books.docLinkText' | translate}}</a></td>
                  </ng-container>
                  <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef class="sizeThead text-center">
                      <b>{{'create_product.actions_crp' | translate}}</b></th>
                    <td mat-cell *matCellDef="let element" class="colSize">
                      <button class="btn" [routerLink]="['/book/edit-product', element.id]"><i
                          class="bi bi-pencil-fill colorIconAction"></i></button>
                      <button class="btn" (click)="deleteProduct(element.id)"><i
                          class="bi bi-trash-fill colorIconAction"></i></button>
                    </td>
                  </ng-container>
                  <!-- columna de cantidad para los usuarios -->
                  <ng-container matColumnDef="quantity">
                    <th mat-header-cell *matHeaderCellDef class="text-center">
                      <b>{{'create_product.quantity_crp' | translate}}</b>
                    </th>
                    <td mat-cell *matCellDef="let element" class="text-center">
                      <b>{{element.quantity}}</b>
                    </td>
                  </ng-container>
                  <!-- columna de disponibilidad para los admin -->
                  <ng-container matColumnDef="availability">
                    <th mat-header-cell *matHeaderCellDef class="text-center">
                      <b>{{ 'create_product.availability_crp' | translate }}</b>
                    </th>
                    <td mat-cell *matCellDef="let element" class="text-center">
                      <b>{{element.availability}}</b>
                    </td>
                  </ng-container>
                  

                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                  <!-- Row shown when there is no matching data that will be provided to the wrapper table. -->
                  <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell no-data-centered" colspan="5">{{'create_product.not_products_crp' | translate}}
                    </td>
                  </tr>

                  <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell no-data-centered" colspan="5">{{'create_product.empty_crp' | translate}}</td>
                  </tr>

                </table>
                <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
              </div>
            </ng-container>
            <ng-template #elseTemplate>
              <br><br><br>
              <div class="row text-center">
                <div class="col">
                  <h2>{{"store.not_products" | translate}}</h2>
                  <br>
                  <button class="sendAddBook" [routerLink]="['/book/shop']">{{'store.go_to_store' | translate}}</button>
                </div>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
</body>

</html>
