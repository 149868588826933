import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { CreateEditorialsBooksComponent } from '../components/create-editorials-books/create-editorials-books.component';
import { EditorialsBooksService } from '../services/editorials-books.service';
import { PageLoadingService } from 'src/app/shared/services/page-loading.service';
import { EditEditorialsBooksComponent } from '../components/edit-editorials-books/edit-editorials-books.component';
import { CommonResponse } from 'src/app/shared/models/reponse.model';

//import { DeleteEditorialsBooksComponent } from '../components/delete-editorials-books/delete-editorials-books.component';
import { DialogData } from 'src/app/shared/models/dialog-data.model';
import { ConfirmationModalComponent } from 'src/app/shared/components/confirmation-modal/confirmation-modal.component';
@Component({
  selector: 'app-editorials-books',
  templateUrl: './editorials-books.component.html',
  styleUrls: ['./editorials-books.component.scss']
})
export class EditorialsBooksComponent implements OnInit {
  editorials: any; // Arreglo para almacenar los datos de la respuesta

    private readonly deleteUserObserver = {
      next: (data: CommonResponse<any>) => this.deleteUserNext(data),
      error: (error: CommonResponse<any>) => this.deleteUserError(error),
      complete: () => this._pageLoadingService.hideLoadingGif()
    };

  constructor(
    private _EditorialsBooksService: EditorialsBooksService,
    private _pageLoadingService: PageLoadingService,
    private _snackbarService: SnackbarService,
    private _dialog: MatDialog
  ) { }

    deleteUserNext(data: CommonResponse<any>) {
      this._pageLoadingService.showLoadingGif();
      this.getAllEditorials();
      this._snackbarService.openStandardSnackBar(`deleteEditorialBook`);
    }

      deleteUserError(error: CommonResponse<any>) {
        //console.log('error',error.message);
        this._pageLoadingService.hideLoadingGif();
        this._snackbarService.openStandardSnackBar(`deleteEditorialExistent`);
      }

  ngOnInit(): void {
    this.getAllEditorials();
  }

  getAllEditorials(){
    this._EditorialsBooksService.getAllEditorials()
    .subscribe({
      next: (data) => {
        this.editorials = data.data;
        //console.log('Datos recibidos:', this.editorials);
      },
      error: (err) => {
        console.error('Error al obtener las editoriales:', err);
      },
      complete: () => {
        //console.log('Petición completada.');
        this._pageLoadingService.hideLoadingGif();// esconde la animacion de cargando cuando se completa la peticion
      }
    });
  }

  addNewEditorialBook(){
    // console.log("abre modal para agregar nueva editorial de libro");

        const confirmPurchaseDialogRef = this._dialog.open(CreateEditorialsBooksComponent, {
          height: '60%',
          width: '50%',
        });

        confirmPurchaseDialogRef.afterClosed().subscribe(result => {
          this._pageLoadingService.showLoadingGif();//muesta animacion de cargando...
          this.getAllEditorials();//se refresca la tabla 
          
        });
  }

  editEditorialBook(){
    //console.log("abre modal para editar la editorial");

    const confirmPurchaseDialogRef = this._dialog.open(EditEditorialsBooksComponent, {
      height: '60%',
      width: '50%',
    });

  }

  showDeleteEditorialBookConfirmation(editorialBookId:number){
    //console.log("abre modal de confirmación eliminar",editorialBookId);

        const data: DialogData = {
          title: 'Confirmar eliminación',
          message: `¿Estás seguro de que deseas eliminar esta editorial?`,
          userInteractResult: false
        };
    
        const confirmDeleteDialogRef = this._dialog.open(ConfirmationModalComponent, {
          width: '350px',
          data: data
        });

        confirmDeleteDialogRef.afterClosed().subscribe(result => {
          this._pageLoadingService.showLoadingGif();
    
          if(result) {
            console.log('hacer la peticion de eliminar',editorialBookId);
    
            this._EditorialsBooksService.deleteEditorialBook(editorialBookId)
              .subscribe(this.deleteUserObserver);
          }
    
          this._pageLoadingService.hideLoadingGif();
        });
  }

}
